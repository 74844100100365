import { useEffect, useState } from 'react';

import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';

import { getMySites, getSitesList } from '../../API/siteApi';
import SiteCard from '../SiteCard';
import useAuth from '../../hooks/useAuth';
import MapPage from './MapPage/MapPage';
import Loader from '../Loader';
import BooleanButton from '../BooleanButton';

const DomainPage = () => {
  const [mySitesList, setMySitesList] = useState([]);
  const [count, setCount] = useState();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [getAll, setGetAll] = useState(0);
  const [isAvailable, setIsAvailable] = useState(2);
  const { userMe } = useAuth();
  const [isMapView, setIsMapView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [withRanking, setWithRanking] = useState(false);
  const [withTotalLeads, setWithTotalLeads] = useState(false);

  useEffect(() => {
    const getMyDomainsList = async () => {
      if (userMe === 'salesperson' && getAll === 0) {
        const response = await getSitesList(
          search,
          page,
          limit,
          isAvailable,
          isMapView,
          withRanking ? 'googleRank' : null,
          withRanking ? 'ASC' : null,
        );
        setCount(response.count);
        setMySitesList(response.sites);
      } else {
        const response = await getMySites(
          search,
          page,
          limit,
          userMe,
          isMapView,
          withRanking ? 'googleRank' : null,
          withRanking ? 'ASC' : null,
        );
        setCount(response.sites.count);
        setMySitesList(response.sites.sites);
      }
      setIsLoading(false);
    };
    getMyDomainsList();
  }, [search, page, limit, getAll, isAvailable, withRanking]);

  useEffect(() => setPage(0), [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (e) => {
    setPage(0);
    setLimit(e.target.value);
  };

  const searchInputHandler = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };

  const handleChangeView = () => {
    setIsLoading(true);
    if (!isMapView) {
      setPage(0);
      setLimit(5000);
    } else {
      setLimit(20);
    }
    setIsMapView(!isMapView);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Typography
        sx={{
          fontWeight: 500,
          '@media (max-width: 600px)': {
            fontSize: '30px',
          },
        }}
        variant="h1">
        Sidor
      </Typography>
      <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
        {userMe === 'salesperson' && (
          <FormControl sx={{ width: '175px' }}>
            <Select size="small" value={getAll} onChange={(e) => setGetAll(e.target.value)}>
              <MenuItem value={0}>Get All</MenuItem>
              <MenuItem value={1}>Get Only My</MenuItem>
            </Select>
          </FormControl>
        )}
        {getAll === 0 && userMe === 'salesperson' && (
          <FormControl sx={{ width: '270px' }}>
            <InputLabel id="isAvailable-label">Lediga</InputLabel>
            <Select
              size="small"
              labelId="isAvailable-label"
              value={isAvailable}
              onChange={(e) => setIsAvailable(e.target.value)}
              label="Lediga">
              <MenuItem value={2}>All sidor</MenuItem>
              <MenuItem value={1}>Lediga sidor</MenuItem>
              <MenuItem value={0}>Inte Lediga sidor</MenuItem>
            </Select>
          </FormControl>
        )}

        <TextField
          id="standard-basic"
          label="Sökord"
          variant="outlined"
          onChange={searchInputHandler}
          value={search}
          size="small"
        />
        <BooleanButton name="Ranking" isActive={withRanking} onChange={setWithRanking} />
        <BooleanButton name="Leads" isActive={withTotalLeads} onChange={setWithTotalLeads} />
        {userMe !== 'client' && (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <Button onClick={handleChangeView}>{isMapView ? 'List' : 'Map'} view</Button>
          </div>
        )}
      </div>
      <Divider my={6} sx={{ margin: '24px 0' }} />

      {isMapView ? (
        <MapPage data={mySitesList} isAllCountryView />
      ) : (
        <Grid container spacing={6}>
          {mySitesList?.map((site) => (
            <SiteCard
              key={site.id}
              site={site}
              withRanking={withRanking}
              withTotalLeads={withTotalLeads}
            />
          ))}
        </Grid>
      )}

      {count && !isMapView && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};

export default DomainPage;
